<template>
  <div>
    <h1>Bienvenue dans la couveuse à génie !</h1>
    <p>
      C’est ici que vous allez découvrir l’apparence du génie qui habite votre question.
      Pour cela, vous n’avez rien d’autre à faire que vous détendre et répondre aux tests qui vous seront proposés avec le plus de spontanéité possible, pas de prise de tête !
      Vos réponses vont réchauffer votre petit génie de l’intérieur et lui permettre d’éclore.
    </p>
    <img style="display: block; margin: auto;" src="../assets/oeufs.png" width="400" />
    <b-button
      class="nextButton"
      style="margin-top: 40px;"
      variant="success"
      @click="$router.push('/step1')"
    >Je fais éclore mon génie !</b-button>
  </div>
</template>

<script>
export default {
  name: "Start"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
