<template>
  <div>
    <div v-if="step === 0">
      <h2>Au cœur de l'arc-en-ciel</h2>
      <p>Concentrez-vous sur votre question et cliquez sur la couleur qui vous attire le plus.</p>
      <color-picker
        v-bind="color"
        @input="onInput"
        style="display: block; margin-right: auto; margin-left: auto;"
      ></color-picker>
    </div>
    <div v-if="step === 1">
      <h2>Une image vaut mille mots</h2>
      <p>Regardez bien les images qui suivent. Cliquez sur celle qui se rapproche le plus de votre question. Il peut s’agir d’un élément visible sur l’image, ou simplement une ambiance, une émotion qu’elle suscite chez vous.</p>
      <carousel-3d :controlsVisible="true">
        <slide v-for="i in 10" :index="i-1" :key="i">
          <img
            :data-index="i"
            :src="getImgUrl('Step1', i)"
            @click="selectImage(i)"
            :style="(imageSelected === i) ? getColorCssFormated() : ''"
          />
        </slide>
      </carousel-3d>
    </div>
    <div v-if="step === 2">
      <h2>Toc toc qui est là ?</h2>
      <p>Votre génie commence à pousser la coquille et pointe le bout de son nez !</p>
      <Creature style="display: inline-block" :head="head" />
    </div>
    <div class="navButtons">
      <b-button
        class="nextButton"
        @click="next()"
        :disabled="this.step === 1 && !this.imageSelected"
        :title="(this.step === 1 && !this.imageSelected) ? 'Sélectionner d\'abord une image :)' : ''"
      >Suivant</b-button>
    </div>
  </div>
</template>

<script>
import Creature from "../components/Creature.vue";
import ColorPicker from "@radial-color-picker/vue-color-picker";
import utils from "@/mixins/utils.mixins.js";
export default {
  name: "Step1",
  mixins: [utils],
  components: { ColorPicker, Creature },
  data() {
    return {
      step: 0,
      head: this.randomBodyPart("head"),
      color: {
        hue: 50,
        saturation: 100,
        luminosity: 50,
        alpha: 1
      }
    };
  },
  mounted: function() {
    localStorage.setItem(
      "color",
      this.hslToHex(
        this.color.hue,
        this.color.saturation,
        this.color.luminosity
      )
    );
  },
  methods: {
    onInput(hue) {
      this.color.hue = hue;
      localStorage.setItem(
        "color",
        this.hslToHex(hue, this.color.saturation, this.color.luminosity)
      );
    },
    next() {
      if (this.step < 2) this.step++;
      else this.$router.push({ name: "step2", params: { head: this.head } });
    },
    hslToHex(h, s, l) {
      // Source : https://stackoverflow.com/questions/36721830/convert-hsl-to-rgb-and-hex
      h /= 360;
      s /= 100;
      l /= 100;
      let r, g, b;
      if (s === 0) {
        r = g = b = l; // achromatic
      } else {
        const hue2rgb = (p, q, t) => {
          if (t < 0) t += 1;
          if (t > 1) t -= 1;
          if (t < 1 / 6) return p + (q - p) * 6 * t;
          if (t < 1 / 2) return q;
          if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
          return p;
        };
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
      }
      const toHex = x => {
        const hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      };
      return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "~@radial-color-picker/vue-color-picker/dist/vue-color-picker.min.css";
</style>