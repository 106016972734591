<template>
  <div>
    <div v-show="step === 0">
      <h2>Du bout des doigts ...</h2>
      <p>Si le sujet qui vous préoccupe pouvait avoir une forme physique, quelle serait sa texture ? Plongez dans les images qui suivent et essayez de ressentir le contact des éléments sous vos doigts. Quelle est la texture qui correspond à votre question ? Cliquez sur l’image pour la sélectionner.</p>
      <carousel-3d :controlsVisible="true">
        <slide v-for="i in 8" :index="i-1" :key="i">
          <img
            :data-index="i"
            :src="getImgUrl('Step2', i)"
            @click="selectImage(i)"
            :style="(imageSelected === i) ? getColorCssFormated() : ''"
          />
        </slide>
      </carousel-3d>
    </div>
    <div v-show="step === 1">
      <h2>Partie de cache-cache</h2>
      <p>La réponse à votre question se trouve dans le paysage ci-dessous. Plongez à l’intérieur, prenez le temps de goûter cet instant. Selon vous, où faut-il chercher votre réponse ? Où irait-elle si elle devait se cacher dans ce paysage ? Plutôt dans le ciel ? Dans l’herbe ? A l’intérieur de la cabane ? Sur le tronc d’un arbre ? Dans la forêt ? Dans le torrent ? Sur une pierre ? Sur une pente, à l’ombre ? Cliquez sur le numéro correspondant à votre réponse.</p>
      <img src="../assets/Step2/choiceImage.png" width="600" @click="step++" />
    </div>
    <div v-show="step === 2">
      <h2>Éclosion en cours...</h2>
      <p>Votre génie commence doucement à sortir de son œuf !</p>
      <Creature style="display: inline-block" :head="head" :body="body" />
    </div>
    <div class="navButtons">
      <b-button
        v-show="this.step !== 1"
        class="nextButton"
        @click="next()"
        :disabled="this.step === 0 && !this.imageSelected"
        :title="(this.step === 0 && !this.imageSelected) ? 'Sélectionner d\'abord une image :)' : ''"
      >Suivant</b-button>
    </div>
  </div>
</template>

<script>
import Creature from "../components/Creature.vue";
import utils from "@/mixins/utils.mixins.js";
export default {
  name: "Step2",
  mixins: [utils],
  components: { Creature },
  props: {
    head: String
  },
  data() {
    return {
      step: 0,
      body: this.randomBodyPart("body")
    };
  },
  methods: {
    next() {
      if (this.step < 2) this.step++;
      else
        this.$router.push({
          name: "step3",
          params: { head: this.head, body: this.body }
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>