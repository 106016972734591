<template>
  <div>
    <div v-show="step === 0">
      <h2>Sagesse animale</h2>
      <p>Imaginez maintenant que vous avez le pouvoir de communiquer avec les animaux. Vers quelle créature vous tourneriez-vous pour avoir une réponse concernant votre question ? Prenez en compte les spécificités de l’animal, si votre question demande de prendre de la distance pourquoi ne pas demander de l’aide à une girafe qui aura une bonne vue d’ensemble de la situation ?</p>
      <carousel-3d :controlsVisible="true">
        <slide v-for="i in 12" :index="i-1" :key="i">
          <img
            :data-index="i"
            :src="getImgUrl('Step3', i)"
            @click="selectImage(i)"
            :style="(imageSelected === i) ? getColorCssFormated() : ''"
          />
        </slide>
      </carousel-3d>
    </div>
    <div v-show="step === 1">
      <h2>La roue des Symboles</h2>
      <p>Pensez à votre question, détendez-vous et posez votre doigt au hasard sur l’une des cases de la roue, comptez 9 cases dans l’ordre que vous voulez et découvrez le symbole qui vous accompagne. Cliquez dessus pour le sélectionner.</p>
      <img src="../assets/Step3/choiceWheel.png" width="500" @click="checkMouseClick($event,this);" />
    </div>
    <div v-show="step === 2">
      <h2>Naissance d'un génie</h2>
      <p>
        Ça y est ! Le processus d’éclosion est arrivé à son terme, votre génie voit enfin le jour et se présente à vous. Il ne vous reste plus qu’à faire connaissance en vous amusant avec les exercices du livret.
        Vous pouvez également découvrir quel objet affectionne votre génie en cliquant sur « accessoire »
      </p>
      <Creature
        id="creature"
        style="display: inline-block;vertical-align: middle;padding-right: 15px"
        :head="head"
        :body="body"
        :foot="foot"
      />
      <b-button
        v-if="!accessory"
        class="getAccessory"
        @click="getAccessory()"
        title="Générer un accessoire"
      >Accessoire</b-button>
      <img v-else id="accessory" :src="accessory" width="150" />
    </div>
    <div class="navButtons">
      <b-button
        v-show="this.step === 0"
        class="nextButton"
        @click="next()"
        :disabled="this.step === 0 && !this.imageSelected"
        :title="(this.step === 0 && !this.imageSelected) ? 'Sélectionner d\'abord une image :)' : ''"
      >Suivant</b-button>
      <b-button v-show="this.step === 2" class="nextButton" @click="reset()">Terminer</b-button>
      <b-button
        v-show="this.step === 2"
        class="printCreature"
        @click="printCreature()"
      >Imprimer mon génie</b-button>
    </div>
  </div>
</template>

<script>
import Creature from "../components/Creature.vue";
import utils from "@/mixins/utils.mixins.js";
export default {
  name: "Step3",
  mixins: [utils],
  components: { Creature },
  props: {
    head: String,
    body: String
  },
  data() {
    return {
      step: 0,
      foot: this.randomBodyPart("foot"),
      accessory: null
    };
  },
  methods: {
    next() {
      if (this.step < 2) this.step++;
      else
        this.$router.push({
          name: "step3",
          params: { head: this.head, body: this.body }
        });
    },
    reset() {
      this.$router.push("/");
    },
    printCreature() {
      const creature = document.getElementById("creature").innerHTML;
      const accessory = document.getElementById("accessory").outerHTML;

      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          <style>
            #creature {
              display: inline-block;
              vertical-align: middle;
              padding-right: 15px;
            }
            img {
              display: block;
              vertical-align: middle;
              border-style: none;
            }
          </style>
        </head>
        <body>
          <div id="creature">
            ${creature}
          </div>
          <div style="display: inline-block;vertical-align: middle;">
            ${accessory}
          </div>
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    getAccessory() {
      const max = 26;
      this.accessory = require("../assets/Accessory/" +
        (Math.floor(Math.random() * (max - 1 + 1)) + 1) +
        ".jpg");
    },
    checkMouseClick(event, img) {
      var posX = event.offsetX ? event.offsetX : event.pageX - img.offsetLeft;
      var posY = event.offsetY ? event.offsetY : event.pageY - img.offsetTop;
      const x_centre = 250;
      const y_centre = 250;
      const rayonC1 = 140;
      const rayonC2 = 227;
      // On vérifie qu'on ne clique pas dans le cercle blanc ni or du cercle car l'image est carrée
      if (
        Math.sqrt(Math.pow(posX - x_centre, 2) + Math.pow(y_centre - posY, 2)) >
          rayonC1 &&
        Math.sqrt(
          Math.pow(posX - x_centre, 2) + Math.pow(y_centre - posY, 2)
        ) <= rayonC2
      ) {
        this.step++;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.printCreature {
  margin-left: 50px;
  background-color: #81cbe9 !important;
  border-color: #81cbe9 !important;
  color: black !important;
}
.getAccessory {
  background-color: #d3d4ba !important;
  border-color: #d3d4ba !important;
  color: black !important;
}
</style>