<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {};
  },
  methods: {}
};
</script>

<style>
@font-face {
  font-family: "Bradleys Pen";
  src: url("assets/fonts/Bradleys_Pen.ttf") format("ttf");
}
#app {
  font-family: "Bradleys Pen", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
body {
  font-family: "Bradleys Pen";
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.navButtons {
  margin-top: 60px;
  margin-bottom: 30px;
}
img {
  cursor: pointer;
}
.carousel-3d-slide {
  border: none !important;
  background: none !important;
}
.nextButton {
  background-color: #e5d3bf !important;
  border-color: #e5d3bf !important;
  color: black !important;
}

p,
h2,
h1,
button {
  font-family: "Bradleys Pen";
}
</style>
