export default {
  data() {
    return {
      imageSelected: null
    };
  },
  methods: {
    randomBodyPart(bodyPart) {
      let max = 10
      switch(bodyPart) {
        case "head":
          max = 17
          break
        case "body":
          max = 12
          break
        case "foot":
          max = 12
          break
      }
      let randomBodyPart = Math.floor(Math.random() * (max - 1 + 1)) + 1;
      return bodyPart + randomBodyPart + ".jpg"
    },
    getImgUrl(step, i) {
      return require('../assets/' + step + '/image' + i + '.png')
    },
    selectImage(i) {
      this.imageSelected = i
    },
    getColorCssFormated() {
      return 'border: 2px solid '+localStorage.getItem('color')
    }
  }
};
