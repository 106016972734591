<template>
  <div>
    <img :src="getImgUrl('Head', head)" width="150" />
    <img v-if="body" :src="getImgUrl('Body', body)" width="150" />
    <img v-if="foot" :src="getImgUrl('Foot', foot)" width="150" />
  </div>
</template>

<script>
export default {
  name: "Creature",
  props: ["head", "body", "foot"],
  data() {
    return {};
  },
  methods: {
    getImgUrl(bodypart, name) {
      return require("../assets/Creature/" + bodypart + "/" + name);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img {
  display: block;
}
</style>
